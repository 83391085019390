/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useState } from "react";
import SEO from "../components/SEO";
import useSearchParams from "@src/utils/useSearchParams";
import HeroSection, { HeroSectionProps, pageMode } from "@src/components/partials/dojo-islands/HeroSection";
import { graphql, useStaticQuery } from "gatsby";
import FeaturesSection, { FeaturesSectionProps } from "@src/components/partials/dojo-islands/FeaturesSection";
import { STATIC_URL } from "@src/components/partials/landing-pages/constants";
import Header, { HeaderProps } from "@src/components/partials/dojo-islands/Header";
import dojoIslandsLogo from "@src/assets/images/dojo-islands/dojo-islands-logo.svg";
import curvedTop from "@src/assets/images/dojo-islands/curved-bg-top.svg";
import curvedBottom from "@src/assets/images/dojo-islands/curved-bg-bottom.svg";
// import TestimonialsSection, {
//   TestimonialsSetcionProps,
// } from "@src/components/partials/dojo-islands/TestimonialsSection";
import CertificationSection, {
  CertificationSectionProps,
} from "@src/components/partials/dojo-islands/CertificationsSection";
import SafetySection, { SafetySectionProps } from "@src/components/partials/dojo-islands/SafetySection";
import InstructionsSection, {
  InstructionsSectionProps,
} from "@src/components/partials/dojo-islands/InstructionsSection";
import { ActivitiesSectionProps } from "../components/partials/dojo-islands/ActivitiesSection";
import ActivitiesSection from "../components/partials/dojo-islands/ActivitiesSection";
import LearningSection, { LearningSectionProps } from "@src/components/partials/dojo-islands/LearningSection";
import ParentsLoveSection, { ParentsLoveSectionProps } from "@src/components/partials/dojo-islands/ParentsLoveSection";
import FaqSection, { FaqSectionProps } from "@src/components/partials/dojo-islands/FaqSection";
import { logEvent } from "@src/utils/logClient";
import TestimonialsSection, {
  TestimonialsSetcionProps,
} from "@src/components/partials/dojo-islands/TestimonialsSection";

const DojoIslands = () => {
  const {
    directus: {
      page_monsterisland,
      page_monsterisland_safety,
      page_monsterisland_instructions,
      page_monsterisland_activities,
      page_monsterisland_parentslove,
    },
  } = useStaticQuery(graphql`
    {
      directus {
        page_monsterisland {
          header_cta_url
          header_parent_cta_url
          header_student_cta_url
          hero_video_url
          cube_image_1 {
            id
            filename_disk
          }
          cube_image_2 {
            id
            filename_disk
          }
          cube_image_3 {
            id
            filename_disk
          }
          features_image {
            id
            filename_disk
          }
          features_image {
            id
            filename_disk
          }
          testimonials_image_1 {
            id
            filename_disk
          }
          testimonials_image_2 {
            id
            filename_disk
          }
          features
          testimonials
          certification_1_badge {
            id
            filename_disk
          }
          certification_2_badge {
            id
            filename_disk
          }
          certification_1_url
          certification_2_url
          safety_image {
            id
            filename_disk
          }
          instructions_image {
            id
            filename_disk
          }
          activities_cta_url
          activities_image {
            id
            filename_disk
          }
          learning_video_url
          parents_love_image_1 {
            id
            filename_disk
          }
          parents_love_image_2 {
            id
            filename_disk
          }
          parents_love_image_3 {
            id
            filename_disk
          }
          faq_questions
        }
        page_monsterisland_safety {
          print_screen {
            id
            filename_disk
          }
        }
        page_monsterisland_instructions {
          illustration {
            id
            filename_disk
          }
        }
        page_monsterisland_activities {
          game_screen {
            id
            filename_disk
          }
        }
        page_monsterisland_parentslove {
          avatar {
            id
            filename_disk
          }
        }
      }
    }
  `);

  const {
    header_cta_url,
    header_parent_cta_url,
    header_student_cta_url,
    hero_video_url,
    cube_image_1,
    cube_image_2,
    cube_image_3,
    features_image,
    features,
    testimonials_image_1,
    testimonials_image_2,
    testimonials,
    instructions_image,
    certification_1_url,
    certification_1_badge,
    certification_2_url,
    certification_2_badge,
    safety_image,
    activities_cta_url,
    activities_image,
    learning_video_url,
    parents_love_image_1,
    parents_love_image_2,
    parents_love_image_3,
    faq_questions,
  } = page_monsterisland;

  const [pageMode, setPageMode] = useState<pageMode>(null);
  const [params] = useSearchParams("mode");

  useEffect(() => {
    logEvent({
      eventName: "web.external_page.dojo_islands.page_view",
      eventValue: window.location.href,
      metadata: { params, pageMode },
    });
  }, []);

  const togglePageMode = () => {
    if (pageMode === "teacher") {
      setPageMode("parent");
    } else {
      setPageMode("teacher");
    }
  };

  const headerProps: HeaderProps = {
    scrollY: 89,
    logo: dojoIslandsLogo,
    cta: {
      label: "directus.page_monsterisland.header_cta_label",
      parent: header_parent_cta_url,
      teacher: header_cta_url,
      student: header_student_cta_url,
    },
  };

  const heroSectionProps: HeroSectionProps = {
    videoUrl: hero_video_url,
    heading: "directus.page_monsterisland.hero_heading",
    description: "directus.page_monsterisland.hero_tagline",
    logo: dojoIslandsLogo,
    toggle: {
      teachersLabel: "directus.page_monsterisland.hero_switch_teacher_label",
      parentsLabel: "directus.page_monsterisland.hero_switch_parent_label",
      handleClick: togglePageMode,
      activeMode: pageMode,
    },
    images: [
      STATIC_URL + cube_image_1.filename_disk,
      STATIC_URL + cube_image_2.filename_disk,
      STATIC_URL + cube_image_3.filename_disk,
    ],
  };

  const featuresSectionProps: FeaturesSectionProps = {
    heading: "directus.page_monsterisland.features_heading",
    description: "directus.page_monsterisland.features_description",
    image: STATIC_URL + features_image.filename_disk,
    features: features.map(({ bacground_url, tempvideo }, index: number) => ({
      title: `directus.page_monsterisland.features.title_${index + 1}`,
      text: `directus.page_monsterisland.features.description_${index + 1}`,
      video: bacground_url,
      tempvideo: tempvideo,
    })),
  };

  const getTestimonialVideo = (index: number) => {
    switch (index) {
      case 0:
        return "https://static.classdojo.com/img/2025/testimonial-carol-compressed.mp4";
      case 1:
        return "https://static.classdojo.com/img/2025/testimonial-jennifer-compressed.mp4";
      case 2:
        return "https://static.classdojo.com/img/2025/testimonial-ed-compressed.mp4";
      default:
        throw new Error("Invalid testimonial index");
    }
  };

  const testimonialsSectionProps: TestimonialsSetcionProps = {
    heading: "directus.page_monsterisland.testimonials_heading",
    images: {
      bgTop: curvedTop,
      bgBottom: curvedBottom,
      sun: STATIC_URL + testimonials_image_1.filename_disk,
      shrooms: STATIC_URL + testimonials_image_2.filename_disk,
    },
    testimonials: testimonials.map(({ vimeo_url }, index: number) => ({
      video: { url: getTestimonialVideo(index), thumb: getTestimonialVideo(index) },
      quote: `directus.page_monsterisland.testimonials.quote_${index + 1}`,
      name: `directus.page_monsterisland.testimonials.name_${index + 1}`,
      position: `directus.page_monsterisland.testimonials.position_${index + 1}`,
    })),
  };

  const instructionSectionProps: InstructionsSectionProps = {
    heading: "directus.page_monsterisland.instructions_heading",
    description: "directus.page_monsterisland.instructions_text",
    instructions: page_monsterisland_instructions.map(({ illustration }, index) => ({
      text: `directus.page_monsterisland_instructions_${index + 1}.text`,
      image: STATIC_URL + illustration.filename_disk,
      url: header_cta_url,
    })),
    image: STATIC_URL + instructions_image.filename_disk,
  };

  const activitiesSectionProps: ActivitiesSectionProps = {
    heading: "directus.page_monsterisland.activities_heading",
    description: "directus.page_monsterisland.activities_tagline",
    activities: page_monsterisland_activities.map(({ game_screen }, index) => ({
      image: STATIC_URL + game_screen.filename_disk,
      title: `directus.page_monsterisland_activities_${index + 1}.title`,
      description: `directus.page_monsterisland_activities_${index + 1}.description`,
      duration: `directus.page_monsterisland_activities_${index + 1}.duration`,
      grade: `directus.page_monsterisland_activities_${index + 1}.recommended_grade`,
      url: header_cta_url,
    })),
    cta: {
      label: "directus.page_monsterisland.activities_cta_text",
      url: activities_cta_url,
    },
    image: STATIC_URL + activities_image.filename_disk,
  };

  const certificationSectionProps: CertificationSectionProps = {
    heading: "directus.page_monsterisland.certifications_heading",
    description: "directus.page_monsterisland.certifications_text",
    badges: {
      coppa: {
        image: STATIC_URL + certification_1_badge.filename_disk,
        url: certification_1_url,
      },
      cssl: {
        image: STATIC_URL + certification_2_badge.filename_disk,
        url: certification_2_url,
      },
    },
  };

  const safetySectionProps: SafetySectionProps = {
    heading: "directus.page_monsterisland.safety_heading",
    description: "directus.page_monsterisland.safety_text",
    image: STATIC_URL + safety_image.filename_disk,
    safety: page_monsterisland_safety.map(({ print_screen }, index) => ({
      image: STATIC_URL + print_screen.filename_disk,
      title: `directus.page_monsterisland_safety_${index + 1}.title`,
    })),
  };

  const learningSectionProps: LearningSectionProps = {
    heading: "directus.page_monsterisland.learning_heading",
    description: "directus.page_monsterisland.learning_text",
    video: learning_video_url,
  };

  const parentsLoveSectionProps: ParentsLoveSectionProps = {
    images: {
      bgTop: curvedTop,
      bgBottom: curvedBottom,
      blueCube: STATIC_URL + parents_love_image_1.filename_disk,
      puzzle: STATIC_URL + parents_love_image_2.filename_disk,
      yellowCube: STATIC_URL + parents_love_image_3.filename_disk,
    },
    heading: "directus.page_monsterisland.parents_love_heading",
    testimonials: page_monsterisland_parentslove.map(({ avatar }, index) => ({
      avatar: STATIC_URL + avatar.filename_disk,
      quote: `directus.page_monsterisland_parentslove_${index + 1}.quote`,
      name: `directus.page_monsterisland_parentslove_${index + 1}.name`,
      position: `directus.page_monsterisland_parentslove_${index + 1}.position`,
      title: `directus.page_monsterisland_parentslove_${index + 1}.title`,
      testimonial: `directus.page_monsterisland_parentslove_${index + 1}.full_testimonial`,
      hasTestimonial: index < 2,
    })),
    ctaLabel: "directus.page_monsterisland.parents_love_cta_label",
  };

  const faqSectionProps: FaqSectionProps = {
    heading: "directus.page_monsterisland.faq_heading",
    faq: faq_questions.map((_, index) => ({
      question: `directus.page_monsterisland.faq_questions.question_${index + 1}`,
      answer: `directus.page_monsterisland.faq_questions.answer_${index + 1}`,
    })),
  };

  useEffect(() => {
    if (params === "teacher" || params === "parent") {
      setPageMode(params);
    } else {
      setPageMode("teacher");
    }
  }, [params]);

  return (
    <div style={{ overflow: "hidden" }}>
      <SEO
        title="Dojo Islands"
        description="A virtual world where kids learn together through play."
        image="https://static.classdojo.com/uploads/47507691-ff64-4ff0-b82e-a7c0c31c8c67.jpg"
      />
      <Header {...headerProps} />
      <HeroSection {...heroSectionProps} />
      {/* TeacherMode */}
      {pageMode === "teacher" && (
        <div>
          <FeaturesSection {...featuresSectionProps} />
          <TestimonialsSection {...testimonialsSectionProps} />
          <InstructionsSection {...instructionSectionProps} />
          <ActivitiesSection {...activitiesSectionProps} />
        </div>
      )}

      {/* ParentMode */}
      {pageMode === "parent" && (
        <div>
          <CertificationSection {...certificationSectionProps} />
          <SafetySection {...safetySectionProps} />
          <LearningSection {...learningSectionProps} />
          <ParentsLoveSection {...parentsLoveSectionProps} />
          <FaqSection {...faqSectionProps} />
        </div>
      )}
    </div>
  );
};
export default DojoIslands;
